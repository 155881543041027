<template>
    <b-row>
        <b-col>
            <b-card>
                <b-card-header>
                    <h4 class="text-center">Pilihan Influencer</h4>
                </b-card-header>
                <b-card-body>
                    <a href="javascript:;" @click="back()">Back</a>
                    <hr>
                    <b-row>
                        <b-col cols="12" class="mb-2 text-center" v-if="influencer">
                            <b-avatar
                                size="120px"
                                variant="light-danger"
                                :text="initial"
                                />
                            <p class="mt-1">{{ influencer.firstName }}</p>
                            <b-button
                                    block
                                    variant="warning"
                                    @click="findInfluencer()"
                                    >
                                    Ubah Influencer
                                </b-button>

                            <b-button
                                    block
                                    variant="primary"
                                    @click="goToStep3()"
                                    >
                                    Selanjutnya
                                </b-button>
                        </b-col>
                        <b-col cols="12" class="text-center" v-else>
                            <p>Belum Ada Influencer</p>
                            <p>Kamu belum menambahkan influencer</p>

                            <p>
                                <b-button
                                    @click="findInfluencer()"
                                    block
                                    variant="warning"
                                    >
                                    Cari Influencer
                                </b-button>
                            </p>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BAvatar
} from 'bootstrap-vue'
import axios from '@axios'
export default {
    name: 'CampaignStep2Assistant',
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BButton,
        BAvatar
    },
    data() {
        return {
            influencer: null,
            initial: null
        }
    },
    mounted() {
        let _ = this
        _.getDetailCampaign()
    },
    methods: {
        findInfluencer() {
            let _ = this
            if ( _.$route.name == 'campaign-edit-2-assistant' ) {
                _.$router.push('/find_influencer?assign_influencer=true&on_edit=true&campaignId=' + _.$route.params.id)
            } else {
                _.$router.push('/find_influencer?assign_influencer=true&campaignId=' + localStorage.getItem('campaignID'))
            }
            
        },
        getDetailCampaign() {
            let _ = this
            let campaignID;
            if ( _.$route.name == 'campaign-create-2-assistant' ) {
                campaignID = localStorage.getItem('campaignID')
            } else {
                campaignID = _.$route.params.id
                localStorage.setItem('campaignID', campaignID)
            }
            axios.get('/advertiser/campaigns/' + campaignID)
                .then(resp => {
                    console.log(resp.data.data.invited)
                    if ( resp.data.data.invited.length > 0) {
                        _.influencer = resp.data.data.invited[0]
                        let initialF = _.influencer.firstName.split('')[0]
                        let initialL = _.influencer.lastName.split('')[0]
                        _.initial = initialF + initialL
                    }
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        goToStep3() {
            let _ = this

            if ( _.$route.name == 'campaign-edit-2-assistant' ) {
                _.$router.push({name: 'campaign-edit-3'})
            } else {
                _.$router.push('step-3')
            }
            
        },
        back(){
            let _ = this
            let campaignID = localStorage.getItem('campaignID') || _.$route.params.id
            _.$router.push({name: 'campaign-edit-1', params: {id: campaignID }})
        }
    }
}
</script>